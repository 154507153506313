<!-- CONTACT FORM START-->
<section class="section part3" id="contact">
    <div class="container">
        <div class="container-part3">
            <div class="first-column-part3">
                <div class="title-button-wrapper">
                    <a href="javascript:void(0);" class="button-como-funciona">Você está quase lá</a>
                </div>
                <div class="text-first-column-wrapper">
                    <h2 class="part3-title"> Confirme o seu e-mail e receba oportunidades incríveis!</h2>
                    <p class="part3-paragraph">Você receberá um e-mail de confirmação em sua caixa (se não
                        estiver na caixa principal, verifique sua caixa de SPAM), após confirmar o seu
                        cadastro, te mandaremos login e senha provisória para que você possa:
                    </p>
                </div>
            </div>
            <div class="second-column-part3" *ngIf="!isMobileScreen">
                <img src="./assets/images/mulher-comemorando.png" alt="mulher comemorando" class="image-second-column-wrapper">
            </div>
        </div>
    </div>
</section>
<!-- CONTACT FORM END-->
