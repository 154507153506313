import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards-obrigado',
  templateUrl: './cards-obrigado.component.html',
  styleUrls: ['./cards-obrigado.component.scss']
})

/**
 * Pricing component
 */
export class CardsObrigadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handleClick() {
    window.scroll(0,0);
  }

}
