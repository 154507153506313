import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-quasela',
  templateUrl: './quasela.component.html',
  styleUrls: ['./quasela.component.scss']
})

/**
 * Pricing component
 */
export class QuaselaComponent implements OnInit {

  isMobileScreen: boolean;

  constructor() {
    this.checkScreenWidth();
   }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    this.isMobileScreen = window.innerWidth < 1024;
  }

  handleClick() {
    window.scroll(0,0);
  }

}
