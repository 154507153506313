<!-- START PRICING -->
<section class="section part2" id="pricing">
    <div class="container">
        <div class="container-part2">
            <div class="second-row-part2">
                <div class="obrigado-blocks first-block-part2 text-center price-box">
                    <div class="icone-block-part2">
                        <img src="./assets/images/mail2.png" alt="ícone e-mail" class="first-icon">
                    </div>
                    <p class="text-block-part2">Navegar pelo Portal e descobrir todas as funcionalidades</p>
                </div>
                <div class="obrigado-blocks second-block-part2 text-center price-box">
                    <div class="icone-block-part2">
                        <img src="./assets/images/martelo2.png" alt="ícone martelo" class="second-icon">
                    </div>
                    <p class="text-block-part2">Descobrir processos que dão match com seu negócio</p>
                </div>
                <div class="obrigado-blocks third-block-part2 text-center price-box">
                    <div class="icone-block-part2">
                        <img src="./assets/images/prof.png" alt="ícone professor" class="third-icon">
                    </div>
                    <p class="text-block-part2">Participar de cursos exclusivos</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END PRICING -->