import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opcp',
  templateUrl: './opcp.component.html',
  styleUrls: ['./opcp.component.scss']
})

/**
 * Features component
 */
export class OpcpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handleClick() {
    window.scroll(0,0);
  }

}
