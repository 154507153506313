<!-- START PRICING -->
<section class="section part2" id="pricing">
    <div class="container">
        <div class="container-part2">
            <div class="row justify-content-center text-center first-row-part2">
                <div class="col-lg-12">
                    <p class="text-muted mt-3 title-subtitle mx-auto component-title">Facilidades</p>
                    <h2 class="title-part2">Oportunidades <span class="span-part2">exclusivas, economia</span>
                    de tempo e muito mais!</h2>
                    <p class="paragraph-part2">Seja informado gratuitamente sobre as maiores oportunidades
                        para sua empresa <span>vender para o governo.</span>
                    </p>
                </div>
            </div>
            <div class="second-row-part2">
                <div class="first-block-part2 text-center price-box">
                    <div class="icone-block-part2">
                        <img src="./assets/images/mail1.png" alt="ícone e-mail" class="first-icon">
                    </div>
                    <p class="title-block-part2">Avisos extraordinários</p>
                    <p class="text-block-part2">Maior chance de vencer! Receba avisos de licitações de
                        <span class="text-block-part2-bold"> baixa concorrência</span>
                    </p>
                </div>
                <div class="second-block-part2 text-center price-box">
                    <div class="icone-block-part2">
                        <img src="./assets/images/robo1.png" alt="ícone robô" class="second-icon">
                    </div>
                    <p class="title-block-part2">IA (Inteligência Artificial) ao seu favor</p>
                    <p class="text-block-part2">Nossa IA altamente treinada irá te indicar as melhores oportunidades exclusivas do portal
                    </p>
                    <div class="button-part2">
                        <a (click)="handleClick()" class="btn btn-part2" href="#navbar">QUERO RECEBER OPORTUNIDADES<i></i></a>
                    </div>
                </div>
                <div class="third-block-part2 text-center price-box">
                    <div class="icone-block-part2">
                        <img src="./assets/images/martelo1.png" alt="ícone martelo" class="third-icon">
                    </div>
                    <p class="title-block-part2">Dispensa de Licitação gratuita</p>
                    <p class="text-block-part2">Com o cadastro de aviso de licitações, você poderá participar
                        <span class="text-block-part2-bold"> gratuitamente</span>
                        de dispensas de licitação.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END PRICING -->
