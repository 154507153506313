import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-comofunciona',
  templateUrl: './comofunciona.component.html',
  styleUrls: ['./comofunciona.component.scss']
})

/**
 * Contact component
 */
export class ComofuncionaComponent implements OnInit {

  isMobileScreen: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    this.isMobileScreen = window.innerWidth < 1199;
  }

  constructor() { }

  ngOnInit(): void {
  }

  handleClick() {
    window.scroll(0,0);
  }

}
