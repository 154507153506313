<!-- CONTACT FORM START-->
<section class="section part3" id="contact">
    <div class="container">
        <div class="container-part3">
            <div class="first-column-part3">
                <div class="title-button-wrapper">
                    <a href="javascript:void(0);" class="button-como-funciona" href="#navbar">Como funciona</a>
                </div>
                <div class="text-first-column-wrapper">
                    <h2 class="part3-title"> Simples, prático e vai te poupar muito tempo.</h2>
                    <p class="part3-paragraph">Basta cadastrar seus dados básicos de contato e informações
                        de linha de fornecimento do produto/serviço da sua empresa.
                        Além de receber avisos de licitações gratuitos, você também
                        terá a oportunidade de participar gratuitamente de dispensa
                        de licitação e se capacitar em nossa Escola de Licitações.
                    </p>
                </div>
                <div class="button-first-column-wrapper">
                    <a (click)="handleClick()" class="btn btn-outline-white btn-round btn-part3" href="#navbar">QUERO RECEBER OPORTUNIDADES<i></i></a>
                </div>
            </div>
            <div class="second-column-part3" *ngIf="!isMobileScreen">
                <img src="./assets/images/homem-segurando-celular.png" alt="homem com celular na mão" class="image-second-column-wrapper">
            </div>
        </div>
    </div>
</section>
<!-- CONTACT FORM END-->
