import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facilidades',
  templateUrl: './facilidades.component.html',
  styleUrls: ['./facilidades.component.scss']
})

/**
 * Pricing component
 */
export class FacilidadesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handleClick() {
    window.scroll(0,0);
  }

}
