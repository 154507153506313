<!-- START FETURES -->
<section class="section part4" id="features">
    <div class="container">
        <div class="container-part5">
            <div class="part5-first-row display-title-part5">
                <p class="text-muted mt-3 title-subtitle mx-auto component-title">O Portal de Compras Públicas</p>
            </div>
            <div class="part5-second-row">
                <div class="imagem-part5-wrapper">
                    <img src="./assets/images/mockup2.png" alt="" class="d-block opcp-img">
                </div>
                <div class="part5-first-row second-display-title-part5">
                    <p class="component-title">O Portal de Compras Públicas</p>
                </div>
                <div class="part5-text-container">
                    <h2 class="part4-text-first-row">Somos facilitadores do seu <strong>sucesso</strong> em licitações!</h2>
                    <p class="part4-text-second-row">Somos uma startup que simplifica e democratiza
                        as licitações. Tornamos os processos de compras públicas mais acessíveis
                        para os negócios brasileiros de todos os segmentos.
                    </p>
                    <p class="part4-text-second-row">Cadastre-se para receber avisos gratuitos de
                        licitações que podem impulsionar o seu negócio.
                    </p>
                    <a (click)="handleClick()" class="btn btn-part4" href="#navbar">QUERO RECEBER OPORTUNIDADES</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END FEATURES -->
